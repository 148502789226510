import axios from "axios";
import { CompanyInt } from "../typescript/interfaces/CompanyInt";
import { JobInt } from "../typescript/interfaces/JobInterface";

export const createCompany = async (companyData: CompanyInt) => {
  const response = await axios.post(
    "https://createcompany-gi2cautoja-rj.a.run.app",
    companyData
  );

  return response.data;
};

export const getCompanyByUser = async (id: string) => {
  try {
    const response = await axios.get(
      `https://getcompanybyuser-gi2cautoja-rj.a.run.app?id=${id}`
    );

    return response.data.company;
  } catch (error) {
    console.error("Error fetching company by user:", error);
    throw error;
  }
};
// deduct post from company and Posts Job
export const deductPostFromCompany = async (companyId: string, job: JobInt) => {
  try {
    console.log("deducting post from company");
    console.log(job);
    console.log(companyId);

    const result = await axios.post(
      `https://deductpostfromcompany-gi2cautoja-rj.a.run.app`,
      { companyId, jobData: job }
    );

    return result.data.message;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return "An error occurred while deducting the post.";
  }
};
