import React from "react";
import Landing from "./pages/Landing";
import PostJob from "./pages/PostJob";
import ComingSoon from "./pages/ComingSoon";
import Jobs from "./pages/Jobs";
import JobDescription from "./pages/JobDescription";
import Applied from "./pages/Applied";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import JobApply from "./pages/JobApply";
import AboutUs from "./pages/AboutUs";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import MessageSent from "./pages/MessageSent";
import EditPost from "./pages/EditPost";

import SignUp from "./pages/Authentication/SignUp";
import PrivateRoute from "./components/routing/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/Authentication/SignIn";
import Unsubscribe from "./pages/Newsletter/Unsubscribe";
import BusinessLanding from "./pages/Landing/BusinessLanding";
import RecoverPassword from "./pages/Authentication/RecoverPassword";
import { UserProvider } from "./components/routing/UserContext";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App" style={{ minHeight: "100vh" }}>
        <UserProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/edit-post" element={<EditPost />} />

              <Route path="/coming-soon" element={<ComingSoon />} />

              <Route path="/job-des" element={<JobDescription />} />
              <Route path="/job-des/job-apply" element={<JobApply />} />
              <Route path="/thank-you" element={<Applied />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/message-sent" element={<MessageSent />} />
              <Route path="/registrar" element={<SignUp />} />
              <Route path="/ingresar" element={<SignIn />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/empresas" element={<BusinessLanding />} />
              <Route path="/recover-password" element={<RecoverPassword />} />

              <Route
                path="/post-job"
                element={
                  <PrivateRoute>
                    <PostJob />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />

              <Route path="*" element={<Navigate to="/jobs" />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </div>
    </LocalizationProvider>
  );
}

export default App;
