import React, { useState, useRef, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface OptionType {
  label: string;
  value: any;
}

interface StandardDropDownProps {
  options: readonly OptionType[];
  value: OptionType;
  onSelect: (option: OptionType) => void;
  placeholder?: string;
}

function StandardDropDown({
  options,
  value,
  onSelect,
  placeholder = "Selecciona...",
}: StandardDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionType>(value);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: OptionType) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div
      className="applicant-dropdown"
      style={{ margin: 0, padding: 0 }}
      ref={dropdownRef}
    >
      <div
        className="applicant-dropdown-box"
        style={{ maxWidth: "400px", margin: 0 }}
      >
        <div
          className={`applicant-dropdown-header ${isOpen ? " adl-open" : ""}`}
          onClick={handleToggle}
        >
          {value?.label || placeholder}
          <span className="applicant-dropdown-arrow">
            {isOpen ? (
              <ArrowDropDownIcon style={{ transform: "rotate(180deg)" }} />
            ) : (
              <ArrowDropDownIcon />
            )}
          </span>
        </div>
        {isOpen && (
          <ul className="applicant-dropdown-list">
            {options.map((option, index) => (
              <div
                key={index}
                className="applicant-dropdown-list-item"
                style={{
                  textDecoration:
                    selectedOption.value === option.value ? "underline" : "",
                }}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default StandardDropDown;
