import React, { useState, useRef, useEffect } from "react";
import "../../assets/styles/resumeUploader.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

interface ResumeUploaderProps {
  setResume: (file: File | undefined) => void;
  status: number;
  loading?: boolean;
}

function ResumeUploader(props: ResumeUploaderProps) {
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loadingMessage, setLoadingMessage] = useState("Analizando CV...");
  const [fadeState, setFadeState] = useState(true);

  useEffect(() => {
    if (props.loading) {
      const messages = [
        "Analizando CV...",
        "Extrayendo datos laborales...",
        "Procesando experiencia profesional...",
        "Analizando información académica...",
        "Validando habilidades...",
      ];
      let currentIndex = 0;

      const intervalId = setInterval(() => {
        setFadeState(false); // Start fade out
        setTimeout(() => {
          currentIndex = (currentIndex + 1) % messages.length;
          setLoadingMessage(messages[currentIndex]);
          setFadeState(true); // Start fade in
        }, 500); // Wait for fade out to complete
      }, 2000); // Change message every 3 seconds

      return () => clearInterval(intervalId);
    }
  }, [props.loading]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined;

    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];

    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      alert("Solo se aceptan archivos .pdf, .doc o .docx");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    const maxSize = 4 * 1024 * 1024; // 4MB in bytes
    if (selectedFile && selectedFile.size > maxSize) {
      alert("El archivo es demasiado grande. El tamaño máximo es 4MB");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    setFile(selectedFile);
    props.setResume(selectedFile);
    setLoadingMessage("Analizando CV...");
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemove = () => {
    // Clear the internal state and prop
    setFile(undefined);
    props.setResume(undefined);
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <React.Fragment>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".pdf,.docx,.doc"
      />
      {props.loading ? (
        <div
          className={`resume-loading-container ${
            fadeState ? "resume-fade-in" : "resume-fade-out"
          }`}
        >
          <div className="resume-loading-message">{loadingMessage}</div>
        </div>
      ) : (
        <button
          type="button"
          onClick={handleClick}
          className="uploader-container"
          disabled={!!file}
        >
          <React.Fragment>
            {file ? "CV Cargado" : "Subir CV"}
            <AutoAwesomeIcon style={{ marginLeft: "10px", fontSize: "20px" }} />
          </React.Fragment>
        </button>
      )}
      {file && (
        <div
          style={{ marginTop: "5px", display: "flex", alignItems: "baseline" }}
        >
          {file.name}
          <button
            type="button"
            className="erase-doc-button laburo-green"
            onClick={handleRemove}
          >
            eliminar
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

export default ResumeUploader;
