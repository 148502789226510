import React, { useEffect } from "react";

import "../../assets/styles/job-sidebar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import PDFViewer from "../PDFViewer";

import AIScore from "../dashboard/tools/AIScore";
import ValidateMap from "../create-job/ValidateMap";

import ApplicantStateDropDown from "../dashboard/tools/ApplicantStateDropDown";
import TextEditorVisualizer from "../text-editor/TextEditorVisualizer";

interface JobDesdPopupProps {
  selectedJob: ApplicantInt;
  setPopOpen: React.Dispatch<React.SetStateAction<boolean>>;

  setApplicantLike: (
    event: React.MouseEvent,
    selectedApplicant: ApplicantInt
  ) => Promise<void>;

  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: "Nuevos" | "Guardados" | "Rechazados"
  ) => Promise<void>;
}

function calculateDuration(startDate: string | Date, endDate: string | Date) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffMonths =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());

  if (diffMonths >= 12) {
    const years = Math.floor(diffMonths / 12);
    const remainingMonths = diffMonths % 12;

    if (remainingMonths === 0) {
      return `${years} ${years === 1 ? "año" : "años"}`;
    }
    return `${years} ${years === 1 ? "año" : "años"} y ${remainingMonths} ${
      remainingMonths === 1 ? "mes" : "meses"
    }`;
  } else {
    return `${diffMonths} ${diffMonths === 1 ? "mes" : "meses"}`;
  }
}

function ApplicantSideBarPopUp({
  selectedJob,
  setPopOpen,
  setApplicantsState,

  setApplicantLike,
}: JobDesdPopupProps) {
  useEffect(() => {
    // Add the class to disable scroll when the component mounts

    if (window.innerWidth < 750) {
      document.body.classList.add("body-no-scroll");

      // Cleanup function to remove the class when the component unmounts
    } else {
      setPopOpen(false);
    }
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [setPopOpen]);

  const handleOpenWhatsApp = (e: React.MouseEvent) => {
    e.preventDefault();
    let phoneNumber = selectedJob?.number?.replace(/\D/g, "") || "";

    if (phoneNumber.startsWith("0")) {
      phoneNumber = phoneNumber.substring(1);
    }

    phoneNumber = `593${phoneNumber}`;

    const whatsappUrl = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      ? `whatsapp://send?phone=${phoneNumber}`
      : `https://wa.me/${phoneNumber}`;

    window.location.href = whatsappUrl;
  };

  const handleCall = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = `tel:${selectedJob?.number}`;
  };

  const handleEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = `mailto:${selectedJob?.email}`;
  };

  return (
    <div className="jsb-popup-container">
      <div className="jsb-popup-window">
        <div className={`w100 job-sidebar-content `} style={{ height: "100%" }}>
          <div className="js-content-top" style={{ gap: "10px" }}>
            <div className="flx" style={{ justifyContent: "space-between" }}>
              <div
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setPopOpen(false);
                }}
                className="flx jsb-back-button"
                style={{ alignItems: "center", fontSize: "18px" }}
              >
                <ArrowBackIcon /> Regresar
              </div>
              <div>
                <ApplicantStateDropDown
                  applicant={selectedJob}
                  setApplicantsState={setApplicantsState}
                />
              </div>
            </div>

            <div
              className="flx w100"
              style={{ justifyContent: "space-between" }}
            >
              <div className="txt-s4 job-post-position">
                {selectedJob.name ? selectedJob.name : "name"}
              </div>
            </div>
            <div
              style={{
                fontSize: "19px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {selectedJob?.email}
              <MailOutlineOutlinedIcon
                onClick={handleEmail}
                style={{
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "18px",
                color: "rgba(0,0,0,0.5)",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {selectedJob?.number}
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <LocalPhoneRoundedIcon
                  onClick={handleCall}
                  style={{
                    cursor: "pointer",
                    verticalAlign: "middle",
                    color: "black",
                    fontSize: "24px",
                  }}
                />
                <WhatsAppIcon
                  onClick={handleOpenWhatsApp}
                  style={{
                    cursor: "pointer",
                    color: "#25D366",
                    verticalAlign: "middle",
                    fontSize: "28px",
                  }}
                />
              </div>
            </div>
            {/* logic to take user to apply or  */}
          </div>
          {/* ----------- */}
          <div className="js-content-btm" style={{ paddingTop: "5px" }}>
            <div className="applicant-info-box">
              {selectedJob?.description && (
                <div className="applicant-section">
                  <div
                    className="txt-s4 title"
                    style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
                  >
                    Información
                  </div>
                  <TextEditorVisualizer htmlContent={selectedJob.description} />
                </div>
              )}

              {selectedJob.answers && (
                <div className="applicant-section">
                  <div
                    className="txt-s4 title"
                    style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
                  >
                    Respuestas del Candidato
                  </div>
                  <div className="flx flx-col" style={{ gap: "15px" }}>
                    {Object.entries(selectedJob.answers).map(
                      ([question, answer], index) => (
                        <div
                          key={index}
                          className="flx flx-col"
                          style={{ gap: "5px", fontSize: "20px" }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              color: "rgba(0,0,0,0.8)",
                              fontSize: "18px",
                            }}
                          >
                            {question}
                          </div>
                          <div
                            style={{
                              color: "rgba(0,0,0,0.6)",
                              fontSize: "16px",
                            }}
                          >
                            {answer}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              {selectedJob.mapResults && (
                <div className="applicant-section">
                  <div
                    className="txt-s4 title"
                    style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
                  >
                    Dirección del Candidato
                  </div>
                  <ValidateMap mapResults={selectedJob.mapResults} />
                </div>
              )}

              {selectedJob.educationList &&
                selectedJob.educationList.length > 0 && (
                  <div className="applicant-section">
                    <div
                      className="txt-s4 title"
                      style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
                    >
                      Educación
                    </div>
                    <div
                      className="education-section__items"
                      style={{
                        gap: "15px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {selectedJob.educationList.map((education, index) => (
                        <div
                          key={index}
                          className="education-item"
                          style={{ padding: "15px", position: "relative" }}
                        >
                          <div className="flx flx-col" style={{ gap: "10px" }}>
                            <div
                              className="txt-s4"
                              style={{ fontWeight: "bold" }}
                            >
                              {education.institucion}
                            </div>
                            <div
                              className="txt-s4"
                              style={{ color: "rgba(0,0,0,0.7)" }}
                            >
                              {education.titulo}
                            </div>
                            <div
                              className="txt-s4"
                              style={{ color: "rgba(0,0,0,0.6)" }}
                            >
                              {education.nivel_educativo}
                            </div>
                            <div
                              className="flx"
                              style={{
                                gap: "5px",
                                fontSize: "16px",
                                color: "rgba(0,0,0,0.60)",
                              }}
                            >
                              <span>
                                {new Date(education.fecha_inicio)
                                  .toLocaleDateString("es-ES", {
                                    month: "2-digit",
                                    year: "2-digit",
                                  })
                                  .replace(/\//g, "/")}
                              </span>
                              <span>-</span>
                              <span>
                                {education.estudiando_actualmente
                                  ? "Presente"
                                  : new Date(education.fecha_fin)
                                      .toLocaleDateString("es-ES", {
                                        month: "2-digit",
                                        year: "2-digit",
                                      })
                                      .replace(/\//g, "/")}
                              </span>
                              <span>
                                (
                                {calculateDuration(
                                  education.fecha_inicio,
                                  education.estudiando_actualmente
                                    ? new Date()
                                    : education.fecha_fin
                                )}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedJob.experienceList &&
                selectedJob.experienceList.length > 0 && (
                  <div className="applicant-section">
                    <div
                      className="txt-s4 title"
                      style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
                    >
                      Experiencia Laboral
                    </div>
                    <div
                      className="work-experience-section__items"
                      style={{
                        gap: "15px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {selectedJob.experienceList.map((experience, index) => (
                        <div
                          key={index}
                          className="work-experience-item"
                          style={{ padding: "15px", position: "relative" }}
                        >
                          <div className="flx flx-col" style={{ gap: "10px" }}>
                            <div
                              className="txt-s4"
                              style={{ fontWeight: "bold" }}
                            >
                              {experience.empresa}
                            </div>
                            <div
                              className="txt-s4"
                              style={{ color: "rgba(0,0,0,0.7)" }}
                            >
                              {experience.puesto}
                            </div>
                            <div
                              className="txt-s4"
                              style={{
                                color: "rgba(0,0,0,0.6)",
                                fontSize: "16px",
                              }}
                            >
                              {experience.descripcion}
                            </div>
                            <div
                              className="flx"
                              style={{
                                gap: "5px",
                                fontSize: "16px",
                                color: "rgba(0,0,0,0.60)",
                              }}
                            >
                              <span>
                                {new Date(experience.fecha_inicio)
                                  .toLocaleDateString("es-ES", {
                                    month: "2-digit",
                                    year: "2-digit",
                                  })
                                  .replace(/\//g, "/")}
                              </span>
                              <span>-</span>
                              <span>
                                {experience.trabajando_actualmente
                                  ? "Presente"
                                  : new Date(experience.fecha_fin)
                                      .toLocaleDateString("es-ES", {
                                        month: "2-digit",
                                        year: "2-digit",
                                      })
                                      .replace(/\//g, "/")}
                              </span>
                              <span>
                                (
                                {calculateDuration(
                                  experience.fecha_inicio,
                                  experience.trabajando_actualmente
                                    ? new Date()
                                    : experience.fecha_fin
                                )}
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              {selectedJob?.score && (
                <div className="applicant-section">
                  <div
                    className="txt-s4 title"
                    style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
                  >
                    Evaluación Laburo
                  </div>
                  {selectedJob?.calculatedScore && (
                    <div style={{ marginTop: "10px" }}>
                      <AIScore score={selectedJob.calculatedScore} />
                    </div>
                  )}

                  {selectedJob.score["Strong Points"].length > 0 && (
                    <>
                      <div style={{ marginBottom: "5px", fontSize: "20px" }}>
                        Puntos a Favor
                      </div>
                      <ul
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "15px",
                          fontSize: "18px",
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        {selectedJob.score?.["Strong Points"].map(
                          (point: string, index) => (
                            <li key={index}>{point}</li>
                          )
                        )}
                      </ul>
                    </>
                  )}

                  {selectedJob.score["Weak Points"].length > 0 && (
                    <>
                      <div
                        style={{
                          marginTop: "15px",
                          marginBottom: "5px",
                          fontSize: "20px",
                        }}
                      >
                        Puntos en Contra
                      </div>
                      <ul
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "15px",
                          fontSize: "18px",
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        {selectedJob.score?.["Weak Points"].map(
                          (point: string, index) => (
                            <li key={index}>{point}</li>
                          )
                        )}
                      </ul>
                    </>
                  )}
                </div>
              )}

              <div
                className="txt-s4 title"
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
              >
                Hoja de Vida
              </div>
              <PDFViewer path={selectedJob.resumeUrl} name={selectedJob.name} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicantSideBarPopUp;
