import { useEffect } from "react";
import { useUser } from "../routing/UserContext";
import { getBusinessUserPosts } from "../../utils/businessUserFunctions";
import { getCompanyByUser } from "../../utils/companyFunctions";
import { getAuth } from "firebase/auth";

export const useInitialData = () => {
  const { userData, setUserData, setCompanyData } = useUser();
  const auth = getAuth();

  useEffect(() => {
    const loadInitialData = async () => {
      if (!userData && auth.currentUser?.email) {
        try {
          console.log("Loading initial data");
          // Load user data
          const posts = await getBusinessUserPosts(auth.currentUser.email);
          setUserData(posts);

          // Load company data if we have a companyId
          if (posts.companyId) {
            const company = await getCompanyByUser(posts.companyId);
            setCompanyData(company);
          }
        } catch (error) {
          console.error("Error loading initial data:", error);
        }
      }
    };

    loadInitialData();
  }, [auth.currentUser, userData, setUserData, setCompanyData]);

  return { isLoading: !userData };
};
