import axios from "axios";
import { ApplicantInt } from "../typescript/interfaces/AppInterface";

const getPostedJobsById = async (id: string) => {
  // get email and lookup all the available applications
  try {
    const result = await axios.get(
      `https://getuserjobsbyid-gi2cautoja-rj.a.run.app?id=${id}`
    );

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

const getApplicantsById = async (id: string) => {
  try {
    const result = await axios.get(
      `https://getapplicantsbyid-gi2cautoja-rj.a.run.app?id=${id}`
    );
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

const updateApplicantById = async (data: ApplicantInt) => {
  try {
    await axios.post(`https://updateapplicant-gi2cautoja-rj.a.run.app`, data);
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
};

const checkApplicantExists = async (jobId: string, email: string) => {
  try {
    const result = await axios.post(
      "https://checkapplicantindb-gi2cautoja-rj.a.run.app",
      { email, jobId }
    );

    if (result.data.message === "Applicant exists") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Unexpected error:", error);
  }
};

const markApplicantAsSeen = async (data: ApplicantInt) => {
  try {
    await axios.post(
      `https://markapplicantasseen-gi2cautoja-rj.a.run.app`,
      data
    );
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return false;
  }
};

const handleApplicantState = async (
  data: ApplicantInt,
  state: "Nuevos" | "Guardados" | "Rechazados"
) => {
  try {
    await axios.post(`https://handleapplicantstate-gi2cautoja-uc.a.run.app`, {
      data,
      newState: state,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const filterApplicantsByState = (
  applicants: ApplicantInt[],
  state: "Nuevos" | "Guardados" | "Rechazados"
) => {
  return applicants.filter((applicant) => applicant.state === state);
};

const parseResume = async (file: File) => {
  const formData = new FormData();
  formData.append("resume", file);
  try {
    const response = await axios.post(
      "https://applicantcvpreprocessinghandler-gi2cautoja-rj.a.run.app",
      formData,
      {
        headers: {
          // Remove the manual 'Content-Type' header
          // "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};

export {
  getPostedJobsById,
  getApplicantsById,
  updateApplicantById,
  checkApplicantExists,
  markApplicantAsSeen,
  handleApplicantState,
  filterApplicantsByState,
  parseResume,
};
