export const INDUSTRIES = [
  { value: "agricultura", label: "Agricultura" },
  { value: "automotriz", label: "Automotriz" },
  { value: "comercio", label: "Comercio" },
  { value: "comercio_electronico", label: "Comercio Electrónico" },
  { value: "construccion", label: "Construcción" },
  { value: "consultoria", label: "Consultoría" },
  { value: "educacion", label: "Educación" },
  { value: "energia", label: "Energía" },
  { value: "farmaceutica", label: "Farmacéutica" },
  { value: "finanzas", label: "Finanzas" },
  { value: "gobierno", label: "Gobierno" },
  { value: "hoteleria_turismo", label: "Hotelería y Turismo" },
  { value: "logistica", label: "Logística" },
  { value: "manufactura", label: "Manufactura" },
  { value: "medios_entretenimiento", label: "Medios y Entretenimiento" },
  { value: "recursos_humanos", label: "Recursos Humanos" },
  { value: "salud", label: "Salud" },
  { value: "seguros", label: "Seguros" },
  { value: "servicios_legales", label: "Servicios Legales" },
  { value: "servicios_profesionales", label: "Servicios Profesionales" },
  { value: "tecnologia", label: "Tecnología" },
  { value: "telecomunicaciones", label: "Telecomunicaciones" },
  { value: "otro", label: "Otro" },
] as const;

export type Industry = (typeof INDUSTRIES)[number]["value"];
