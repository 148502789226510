import React, { useEffect } from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import "../../assets/styles/dashboard/applicants.css";
import EmailIcon from "@mui/icons-material/Email";

import PDFViewer from "../PDFViewer";
import { Phone } from "@mui/icons-material";

import AIScore from "../dashboard/tools/AIScore";
import ValidateMap from "../create-job/ValidateMap";
import ApplicantStateDropDown from "../dashboard/tools/ApplicantStateDropDown";
import TextEditorVisualizer from "../text-editor/TextEditorVisualizer";
interface ApplicantSideInfoProps {
  selected: ApplicantInt;
  setApplicantsState: (
    event: React.MouseEvent<HTMLDivElement>,
    selectedApplicant: ApplicantInt,
    state: "Nuevos" | "Guardados" | "Rechazados"
  ) => Promise<void>;
}

function calculateDuration(startDate: string | Date, endDate: string | Date) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffMonths =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());

  if (diffMonths >= 12) {
    const years = Math.floor(diffMonths / 12);
    const remainingMonths = diffMonths % 12;

    if (remainingMonths === 0) {
      return `${years} ${years === 1 ? "año" : "años"}`;
    }
    return `${years} ${years === 1 ? "año" : "años"} y ${remainingMonths} ${
      remainingMonths === 1 ? "mes" : "meses"
    }`;
  } else {
    return `${diffMonths} ${diffMonths === 1 ? "mes" : "meses"}`;
  }
}

function ApplicantSideInfo(props: ApplicantSideInfoProps) {
  useEffect(() => {
    // Change selector to target the scrollable container
    const scrollableBox = document.querySelector(
      ".applicant-sidebar-right-box"
    );
    if (scrollableBox) {
      scrollableBox.scrollTop = 0;
    }
  }, [props.selected]);

  return (
    <div className="applicant-info-container">
      <div className="applicant-info-box">
        <div
          className="txt-s6 "
          style={{ overflow: "hidden", maxWidth: "300px" }}
        >
          {props.selected.name}
        </div>
        <ApplicantStateDropDown
          setApplicantsState={props.setApplicantsState}
          applicant={props.selected}
        />

        <div className="applicant-section">
          <div className="flx flx-col">
            <div
              className="txt-s4 title"
              style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
            >
              Contacto
            </div>
            <div
              className="txt-s4 flx "
              style={{ alignItems: "center", gap: "10px" }}
            >
              <EmailIcon style={{ fontSize: "18px" }} /> {props.selected.email}
            </div>
            <div
              className="txt-s4 flx "
              style={{ alignItems: "center", gap: "10px" }}
            >
              <Phone style={{ fontSize: "18px" }} /> {props.selected.number}
            </div>
          </div>
        </div>
        {props.selected.answers &&
          Object.entries(props.selected.answers).length > 0 && (
            <div className="applicant-section">
              <div
                className="txt-s4 title"
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
              >
                Respuestas del Candidato
              </div>
              <div className="flx flx-col" style={{ gap: "15px" }}>
                {Object.entries(props.selected.answers).map(
                  ([question, answer], index) => (
                    <div
                      key={index}
                      className=" flx flx-col"
                      style={{ gap: "5px", fontSize: "20px" }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "18px",
                        }}
                      >
                        {question}
                      </div>
                      <div
                        style={{
                          color: "rgba(0,0,0,0.7)",

                          fontSize: "16px",
                        }}
                      >
                        {answer}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

        {props.selected.mapResults && (
          <div className="applicant-section">
            <div
              className="txt-s4 title"
              style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
            >
              Dirección Candidato
            </div>
            <ValidateMap mapResults={props.selected.mapResults} />
          </div>
        )}
        {props.selected.educationList &&
          props.selected.educationList.length > 0 && (
            <div className="applicant-section">
              <div
                className="txt-s4 title"
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
              >
                Educación
              </div>
              <div
                className="education-section__items"
                style={{
                  gap: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {props.selected.educationList.map((education, index) => (
                  <div
                    key={index}
                    className="education-item"
                    style={{ padding: "15px", position: "relative" }}
                  >
                    <div className="flx flx-col" style={{ gap: "10px" }}>
                      <div className="txt-s4" style={{ fontWeight: "bold" }}>
                        {education.institucion}
                      </div>
                      <div
                        className="txt-s4"
                        style={{ color: "rgba(0,0,0,0.7)" }}
                      >
                        {education.titulo}
                      </div>
                      <div
                        className="txt-s4"
                        style={{ color: "rgba(0,0,0,0.6)" }}
                      >
                        {education.nivel_educativo}
                      </div>
                      <div
                        className="flx"
                        style={{
                          gap: "5px",
                          fontSize: "16px",
                          color: "rgba(0,0,0,0.60)",
                        }}
                      >
                        <span>
                          {new Date(education.fecha_inicio)
                            .toLocaleString("es-ES", {
                              month: "long",
                              year: "numeric",
                            })
                            .replace(/^\w/, (c) => c.toUpperCase())}
                        </span>
                        <span>-</span>
                        <span>
                          {education.estudiando_actualmente
                            ? "Presente"
                            : new Date(education.fecha_fin)
                                .toLocaleString("es-ES", {
                                  month: "long",
                                  year: "numeric",
                                })
                                .replace(/^\w/, (c) => c.toUpperCase())}
                        </span>
                        <span>
                          (
                          {calculateDuration(
                            education.fecha_inicio,
                            education.estudiando_actualmente
                              ? new Date()
                              : education.fecha_fin
                          )}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        {props.selected.experienceList &&
          props.selected.experienceList.length > 0 && (
            <div className="applicant-section">
              <div
                className="txt-s4 title"
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
              >
                Experiencia Laboral
              </div>
              <div
                className="work-experience-section__items"
                style={{
                  gap: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {props.selected.experienceList.map((experience, index) => (
                  <div
                    key={index}
                    className="work-experience-item"
                    style={{ padding: "15px", position: "relative" }}
                  >
                    <div className="flx flx-col" style={{ gap: "10px" }}>
                      <div className="txt-s4" style={{ fontWeight: "bold" }}>
                        {experience.empresa}
                      </div>
                      <div
                        className="txt-s4"
                        style={{ color: "rgba(0,0,0,0.7)" }}
                      >
                        {experience.puesto}
                      </div>
                      <div
                        className="txt-s4"
                        style={{ color: "rgba(0,0,0,0.6)", fontSize: "16px" }}
                      >
                        {experience.descripcion}
                      </div>
                      <div
                        className="flx"
                        style={{
                          gap: "5px",
                          fontSize: "16px",
                          color: "rgba(0,0,0,0.60)",
                        }}
                      >
                        <span>
                          {new Date(experience.fecha_inicio)
                            .toLocaleString("es-ES", {
                              month: "long",
                              year: "numeric",
                            })
                            .replace(/^\w/, (c) => c.toUpperCase())}
                        </span>
                        <span>-</span>
                        <span>
                          {experience.trabajando_actualmente
                            ? "Presente"
                            : new Date(experience.fecha_fin)
                                .toLocaleString("es-ES", {
                                  month: "long",
                                  year: "numeric",
                                })
                                .replace(/^\w/, (c) => c.toUpperCase())}
                        </span>
                        <span>
                          (
                          {calculateDuration(
                            experience.fecha_inicio,
                            experience.trabajando_actualmente
                              ? new Date()
                              : experience.fecha_fin
                          )}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        {props.selected.educationList &&
          props.selected.educationList.length > 0 && (
            <div className="applicant-section">
              <div
                className="txt-s4 title"
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "23px" }}
              >
                Información Adicional
              </div>

              <TextEditorVisualizer htmlContent={props.selected.description} />
            </div>
          )}
        {props.selected.score && (
          <div className="applicant-section">
            <div
              className="txt-s4"
              style={{
                color: "rgba(0,0,0,0.6",

                fontSize: "23px",
              }}
            >
              <div className="title">Evaluación Laburo</div>
              {props.selected?.calculatedScore && (
                <AIScore score={props.selected?.calculatedScore} />
              )}
            </div>
            {props.selected.score["Strong Points"].length > 0 && (
              <>
                <div style={{ marginBottom: "5px", fontSize: "20px" }}>
                  Puntos a Favor
                </div>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "15px",
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  {props.selected.score?.["Strong Points"].map(
                    (point: string, index) => {
                      return <li key={index}>{point}</li>;
                    }
                  )}
                </ul>
              </>
            )}
            {props.selected.score["Weak Points"].length > 0 && (
              <>
                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "5px",
                    fontSize: "20px",
                  }}
                >
                  Puntos en Contra
                </div>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "15px",
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  {props.selected.score?.["Weak Points"].map(
                    (point: string, index) => {
                      return <li key={index}>{point}</li>;
                    }
                  )}
                </ul>
              </>
            )}
          </div>
        )}

        <div
          className="txt-s4"
          style={{ color: "rgba(0,0,0,0.6", fontSize: "23px" }}
        >
          Hoja de Vida / CV
        </div>
        <PDFViewer path={props.selected.resumeUrl} name={props.selected.name} />
      </div>
    </div>
  );
}

export default ApplicantSideInfo;
