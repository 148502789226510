import { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import SubBar from "../components/dashboard/SubBar";
import Positions from "../components/dashboard/Positions";
import Applicants from "../components/dashboard/Applicants";
import { getPostedJobsById } from "../utils/applicantFunctions";
import { getAuth } from "firebase/auth";
import { JobInt } from "../typescript/interfaces/JobInterface";
import LoadingWidget from "../components/widgets/LoadingWidget";
import { Link, useLocation } from "react-router-dom";
import { getBusinessUserPosts } from "../utils/businessUserFunctions";
import CompanyForm from "../components/dashboard/forms/CompanyForm";
import { Alert, Snackbar } from "@mui/material";
import { getCompanyByUser } from "../utils/companyFunctions";

import { useUser } from "../components/routing/UserContext";

function Dashboard() {
  const { userData, setUserData, companyData, setCompanyData } = useUser();
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [selectedPage, setSelectedPage] = useState(() => {
    return localStorage.getItem("currentPage") || "vacantes";
  });
  const [isLoading, setIsLoading] = useState(true);
  const [postsAvailable, setPostsAvailable] = useState<number>(0);
  const [companyFormOpen, setCompanyFormOpen] = useState(false);
  const [currPositions, setCurrPositions] = useState<JobInt[] | undefined>();
  const [showAlert, setShowAlert] = useState(false);
  const alert = useLocation().state?.alert;
  const auth = getAuth();
  const [showJobAlert, setShowJobAlert] = useState(false);
  const [showEraseJobAlert, setShowEraseJobAlert] = useState(false);
  const [jobAlertMessage, setJobAlertMessage] = useState("");
  const [jobAlertSeverity, setJobAlertSeverity] = useState<"success" | "error">(
    "success"
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobSuccess = searchParams.get("success");

  const getInitialPositions = useCallback(async () => {
    if (auth.currentUser) {
      try {
        const user = auth.currentUser.email;
        const positions = await getPostedJobsById(user as string);
        setCurrPositions(positions);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    }
  }, [auth]);

  const getPostsAvailable = useCallback(async () => {
    if (auth.currentUser) {
      try {
        setIsLoadingPosts(true);
        const user = auth.currentUser.email;
        const posts = await getBusinessUserPosts(user as string);
        setUserData(posts);
        setCompanyFormOpen(!posts.companyId);
      } catch (error) {
        console.error("Error fetching available posts:", error);
      } finally {
        setIsLoadingPosts(false);
      }
    }
  }, [auth, setUserData]);

  useEffect(() => {
    getInitialPositions();
    getPostsAvailable();
  }, [getInitialPositions, getPostsAvailable]);

  useEffect(() => {
    // Store the current page number in localStorage
    localStorage.setItem("currentPage", selectedPage);
  }, [selectedPage]);

  useEffect(() => {
    if (alert) {
      setShowAlert(true);
      window.history.replaceState({}, document.title);
    }
  }, [alert]);

  useEffect(() => {
    if (userData?.companyId) {
      const fetchCompanyData = async () => {
        try {
          const company = await getCompanyByUser(userData.companyId as string);
          setPostsAvailable(company.maxPostsAllowed - company.currentPosts);
          setCompanyData(company);
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };
      fetchCompanyData();
    }
  }, [userData, setCompanyData]);

  useEffect(() => {
    if (jobSuccess !== null) {
      setShowJobAlert(true);
      if (jobSuccess === "true") {
        setJobAlertMessage("Vacante publicada exitosamente");
        setJobAlertSeverity("success");
        localStorage.removeItem("draftJob");
        localStorage.removeItem("jobStep");
      } else {
        setJobAlertMessage("Error al publicar la vacante. Intenta nuevamente.");
        setJobAlertSeverity("error");
      }
      // Clean up the URL
      window.history.replaceState({}, "", location.pathname);
    }
  }, [jobSuccess, location.pathname]);

  const refreshDashboard = useCallback(() => {
    getInitialPositions();
    getPostsAvailable();
  }, [getInitialPositions, getPostsAvailable]);

  return (
    <div className="w100 flx flx-col " style={{ minHeight: "100vh" }}>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowAlert(false)}
          severity={
            alert?.message === "Empresa creada exitosamente"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {alert?.message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showJobAlert}
        autoHideDuration={6000}
        onClose={() => setShowJobAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowJobAlert(false)}
          severity={jobAlertSeverity}
          sx={{ width: "100%" }}
        >
          {jobAlertMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showEraseJobAlert}
        autoHideDuration={6000}
        onClose={() => setShowEraseJobAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowEraseJobAlert(false)}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          Error al eliminar la vacante
        </Alert>
      </Snackbar>

      <Navbar
        scrollPast={true}
        lockNavbar={true}
        highlightDash={true}
        hideDash={true}
      />
      {isLoadingPosts ? (
        <div className="flx flx-center mt-25">
          <LoadingWidget loading={true} />
        </div>
      ) : companyFormOpen ? (
        <CompanyForm onCompanyCreated={refreshDashboard} />
      ) : (
        <>
          <SubBar
            setSelectedPage={setSelectedPage}
            loading={isLoading}
            jobsAvailable={currPositions?.length as number}
          />

          <div>
            {!isLoading && currPositions && companyData ? (
              selectedPage === "vacantes" ? (
                // react fragment and put the different ones for mobile and desktop
                <Positions
                  positions={currPositions}
                  getPositions={getInitialPositions}
                  postsAvailable={postsAvailable}
                  companyData={companyData}
                  refreshDashboard={refreshDashboard}
                  setShowEraseJobAlert={setShowEraseJobAlert}
                />
              ) : currPositions.length > 0 ? (
                <Applicants jobs={currPositions} />
              ) : (
                <div className="flx-col flx-center txt-s4 mt-50 ">
                  <div
                    className="add-pos-info-container-box"
                    style={{ textAlign: "center" }}
                  >
                    ¡Añade tu primera vacante para empezar a recibir aplicantes!
                  </div>
                  <div>
                    {/* use global state management */}
                    <Link to="/post-job" className="button-hover">
                      <div
                        className="add-position-btn flx flx-center"
                        style={{ whiteSpace: "nowrap", borderRadius: "50px" }}
                      >
                        <div className="">Crear Vacante</div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <div className="flx flx-center mt-25">
                <LoadingWidget loading={isLoading} />
              </div>
            )}
          </div>
        </>
      )}

      {/* 
      <SignOut /> */}
    </div>
  );
}

export default Dashboard;
