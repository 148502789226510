import React, { useEffect, useState } from "react";

import StandardDropDown from "../../standard/dropdown/StandardDropDown";
import { INDUSTRIES } from "../../../utils/constants/Industries";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createCompany } from "../../../utils/companyFunctions";
import { CompanyInt } from "../../../typescript/interfaces/CompanyInt";
import useAuth from "../../../utils/useAuth";
import { useNavigate } from "react-router-dom";

// Add prop type for refresh callback
interface CompanyFormProps {
  onCompanyCreated?: () => void;
}

function CompanyForm({ onCompanyCreated }: CompanyFormProps) {
  const navigate = useNavigate();

  const { user } = useAuth();
  const [formData, setFormData] = useState<CompanyInt>({
    name: "",
    industry: "",
    contactName: "",
    contactLastName: "",
    contactNumber: "",
    numberOfEmployees: "",
    contactEmail: user?.email ?? "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (user?.email) {
      setFormData((prev) => ({
        ...prev,
        contactEmail: user.email || "",
      }));
    }
  }, [user]);

  const validateForm = () => {
    if (!formData.name || !formData.industry || !formData.numberOfEmployees) {
      setError("Por favor complete todos los campos de la empresa");
      return false;
    }
    if (
      !formData.contactName ||
      !formData.contactLastName ||
      !formData.contactNumber
    ) {
      setError("Por favor complete todos los campos de contacto");
      return false;
    }
    if (!formData.contactEmail) {
      setError("El email de contacto es requerido");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const response = await createCompany(formData);

      // Call the refresh callback if provided
      if (onCompanyCreated) {
        onCompanyCreated();
      }

      navigate("/dashboard", {
        state: {
          alert: {
            type: "alert",
            message: response.message,
          },
        },
      });
    } catch (err: any) {
      setError(err.message || "Error al crear la empresa");
    } finally {
      setLoading(false);
    }
  };

  const employeeRanges = [
    { value: "1-10", label: "1-10 empleados" },
    { value: "11-50", label: "11-50 empleados" },
    { value: "51-200", label: "51-200 empleados" },
    { value: "201-500", label: "201-500 empleados" },
    { value: "501+", label: "Más de 500 empleados" },
  ];

  const renderStep1 = () => (
    <>
      <h3>Información de la Empresa</h3>
      <input
        type="text"
        placeholder="Nombre de la Empresa"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />

      <StandardDropDown
        options={[...INDUSTRIES]}
        value={{
          value: formData.industry,
          label:
            INDUSTRIES.find((industry) => industry.value === formData.industry)
              ?.label ?? formData.industry,
        }}
        onSelect={(option) => {
          setFormData((prev) => ({
            ...prev,
            industry: option.value,
          }));
        }}
        placeholder="Industria"
      />

      <StandardDropDown
        options={employeeRanges}
        value={{
          value: formData.numberOfEmployees,
          label: formData.numberOfEmployees
            ? employeeRanges.find(
                (range) => range.value === formData.numberOfEmployees
              )?.label ?? formData.numberOfEmployees
            : "",
        }}
        onSelect={(option) => {
          setFormData((prev) => ({
            ...prev,
            numberOfEmployees: option.value,
          }));
        }}
        placeholder="Número de empleados"
      />

      <button type="button" onClick={() => setStep(2)}>
        Siguiente
      </button>
    </>
  );

  const renderStep2 = () => (
    <>
      <h3>Información de Contacto</h3>
      <input
        type="text"
        placeholder="Nombre del Contacto"
        name="contactName"
        value={formData.contactName}
        onChange={handleChange}
        required
      />

      <input
        type="text"
        placeholder="Apellido del Contacto"
        name="contactLastName"
        value={formData.contactLastName}
        onChange={handleChange}
        required
      />

      <input
        type="tel"
        placeholder="Número de contacto"
        name="contactNumber"
        value={formData.contactNumber}
        onChange={handleChange}
        required
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <button style={{ width: "100%" }} type="submit" disabled={loading}>
          {loading ? "Creando..." : "Registrar Empresa"}
        </button>
      </div>
      <span
        onClick={() => setStep(1)}
        style={{
          cursor: "pointer",
          color: "var(--laburo-green)",
        }}
      >
        <span
          onClick={() => setStep(1)}
          style={{
            cursor: "pointer",
            color: "var(--laburo-green)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ArrowBackIcon style={{ marginRight: "5px" }} />
          Regresar
        </span>
      </span>
    </>
  );

  return (
    <div
      style={{
        padding: "0px 10px",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        marginTop: "var(--nav-height)",
      }}
    >
      <div className="signinContainer__box" style={{ maxWidth: "500px" }}>
        <div className="signinContainer__box__inner">
          <h1>Registrar Empresa</h1>
          <form className="signinContainer__box__form" onSubmit={handleSubmit}>
            {step === 1 ? renderStep1() : renderStep2()}
          </form>
          {error && (
            <p className="error-message" style={{ color: "red" }}>
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyForm;
